<template>
  <div class="pastBox">
    <div ref="dEcharts" id="dEcharts"></div>
  </div>
</template>

<script>
  import * as echarts from 'echarts';
  export default {
    name: 'dEcharts',
    data() {
      return {
        screenWidth: document.body.clientWidth,
        echartsRadius:[60,70],
      }
    },
    props:{
      msg:{
        type:Object,
      }
    },
    created(){
      if(this.screenWidth<500){
        this.echartsRadius = [50,60];
      };  
    },
    mounted() {
      this.myCharts();
    },
    methods: {
      myCharts() {
        var dEcharts = this.$echarts.init(this.$refs.dEcharts);
        var scaleData = [
          {
            name: "空",
            value: this.msg.dKongBili,
          },
          {
            name: "多",
            value: this.msg.dDuoBili,
          },
        ];
        var rich = {
          white: {
            color: "#313234",
            align: "center",
            fontSize:'13',
            padding: [-5, 0],
          },
        };
        var placeHolderStyle = {
          normal: {
            label: {
              show: false,
            },
            labelLine: {
              show: false,
              normal:{  
                length: 0, // 修改引导线第一段的长度
                length2: 0, // 修改引导线第二段的长度
                lineStyle: {
                  color: "red" // 修改引导线的颜色
                }
              },
            },
            color: "rgba(0, 0, 0, 0)",
            borderColor: "rgba(0, 0, 0, 0)",
            borderWidth: 0,
          },
        };
        var data = [
          {
            label:{color:'red'},
          },
          {
            label:{color:'green'},
          },
        ];
        var colorList = ['#EA7267', '#F0D84B'];
        for (var i = 0; i < scaleData.length; i++) {
          data.push(
            {
              value: scaleData[i].value,
              name: scaleData[i].name,
              itemStyle: {
                normal: {
                  borderWidth: 5,
                  shadowBlur: 30,
                  borderColor: new echarts.graphic.LinearGradient(0, 0, 1, 1, [
                    // {
                    //   offset: 0,
                    //   color: "#34c26c",
                    // },
                    // {
                    //   offset: 1,
                    //   color: "#e86259",
                    // },
                  ]),
                  shadowColor: "#ffffff",
                },
              },
            },
            {
              value: 2,
              name: "",
              itemStyle: placeHolderStyle,
            }
          );
        }
        var seriesObj = [
          {
            name: "",
            type: "pie",
            clockWise: false,
            radius: this.echartsRadius,
            hoverAnimation: false,
            data: data.map((item, index)=> {
              item.label = { 
                color: colorList[index]
              }
              return item;
            }),
            itemStyle: {
              normal: {
                borderRadius: 60,
                label: {
                  show: true,
                  position: "outside",
                  color: "#313234",
                  fontSize:'13',
                  distanceToLabelLine: -15,
                  formatter: function (params) {
                    var percent = 0;
                    var total = 0;
                    for (var i = 0; i < scaleData.length; i++) {
                      total += scaleData[i].value;
                    }
                    percent = ((params.value / total) * 100).toFixed(0);
                    if (params.name !== "") {
                      return params.name + "{white|" + "：" + percent + "%}";
                    } else {
                      return "";
                    }
                  },
                  rich: rich,
                },
                labelLine: {
                  show: false,
                  normal:{  
                    length: 0, // 修改引导线第一段的长度
                    length2: 0, // 修改引导线第二段的长度
                    lineStyle: {
                      color: "red" // 修改引导线的颜色
                    }
                  },
                },
              },
              color:(params)=> {
                var index = params.dataIndex;
                return colorList[index];
              },
            },
          },
        ];
        var option = {
          color: ['#24ba61', '#dc3d37'],
          backgroundColor: "#fff",
          tooltip: {
            show: false,
          },
          legend: {
            show: false,
          },
          toolbox: {
            show: false,
          },
          series: seriesObj,
        };
        dEcharts.setOption(option);
      }
    }
  }
</script>

<style lang="less" scoped>
  .pastBox {
    width: 100%;
    height: 100%;

    #dEcharts {
      margin: 0 auto;
      width: 100%;
      height: 100%;
    }
  }
</style>